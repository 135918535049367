.footer {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 60px);
  margin-left: 60px;
  height: 40px;
  color: grey;
}

.light-sky-blue {
    color: #025ab1;
}

.text-left {
  padding-left: 20px;
  width: 100%;
  text-align: left;
}

.text-right {
  padding-right: 20px;
  width: 100%;
  text-align: right;
}

@media only screen and (max-width: 770px) {
  .footer {
    margin-left: 0px;
    width: 100%;
  }
}
