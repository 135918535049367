.members-title {
    margin-bottom: 10px;
}

.add-member-container {
    text-align: right;
    margin-top: -10px;
    margin-bottom: 20px;
}

.allocated-projects {
    margin: 10px 0px 0px 0px;
    display: flex;
}

.allocated-title {
    padding: 6px 10px 0px 0px;
}

.allocated-bar {
    width: 300px;
    max-width: calc(100% - 50px);
}

.collabs {
    padding-top: 20px;
}

.error-msg {
    color: red;
    font-size: 13px;
    font-weight: normal;
    margin: 15px 0px 15px 0px !important;
}

.modal-msg {
    text-align: left;
}

.add-member-input {
    display: block !important;
    margin-bottom: 10px;
}

.member-list {
    padding: 0px 0px 20px 0px;
}

.add-input-field {
    padding: 20px 0px 10px 0px;
}

.add-member-label {
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 7px;
}
