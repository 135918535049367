.details-item-container {
    display: flex;
}

.details-item-image {
  margin-right: 10px;
  margin-top: 2px;
}

.details-item-icon {
  margin-right: 10px;
  margin-top: 7px;
}

.details-item-icon > svg {
  font-size: 1.5em;
}

.details-item-header {
  font-weight: 400;
}

.details-item-description {
  font-weight: 300;
  color: rgb(100, 100, 100);
}
