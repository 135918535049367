
.ui.sub.header.invitations-sub-header {
    margin-top: -10px;
}

.ui.divided.items > .item.invitation {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.divided.items .invitation-content {
    flex-grow: 1;
}

.ui.divided.items .invitation-meta {
    margin: 7px 0px;
}

.ui.divided.items .invitation-icon {
    display: inline-block;
    line-height: 5rem;
    font-size: 4.5rem;
    width: 100px;
    text-align: center;
}

.ui.divided.items .invitation-extra {
    display: inline-block;
    flex-basis: 100px;
    width: auto;
}

.ui.divided.items .no-invite {
    font-size: 1.2rem;
}