.autocase-logo {
  width: 400px;
}

.homepage-content {
  padding-top: 50px;
}

.description {
  padding: 10px 0px 10px 0px;
  font-size: 18px;
  line-height: 1.15;
  font-weight: 300;
}

.my-account {
  background-color: white;
  margin: 20px 0px 0px 0px;
}

.third-party > li {
  line-height: 1.3;
}

@media only screen and (max-width: 770px) {
  .autocase-logo {
    width: 250px;
    margin: 30px 0px 20px 0px;
  }
}
