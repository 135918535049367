.organization {
    padding-top: 40px;
    padding-bottom: 3.5rem
}

.back {
    color: black;
    background: transparent !important;
    font-size: 18px !important;
}

.back > svg {
    margin-right: 10px;
}

.chevron {
    font-size: 21px !important;
}

.org-tabs {
    padding-bottom: 30px;
}

.org-squares {
    top: 160px;
    left: calc(50% - 40px);
    width: 80px;
}

.org-square {
    top: 240px;
    left: calc(50% - 30px);
    width: 30px;
}
