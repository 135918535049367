.subtext {
    text-align: left;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.7);
}
.org-list-item {
    padding: 5px 0px 5px 0px;
}
.collab-item {
    color: rgb(100, 100, 100);
}
