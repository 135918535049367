.stats-info {
    margin-top: 20px;
}

.circular {
  margin: 10px 15px;
  line-height: normal;
  min-width: 170px;
  min-height: 170px;
  max-width: 170px;
  max-height: 170px;
}

.circle-container {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}

.circle-number {
  font-size: 25px;
  margin-top: -1px;
  color: rgb(80, 80, 80);
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
}

.circle-unlimited {
  font-size: 23px;
}

.circle-text {
  font-size: 14px;
  color: rgb(120, 120, 120);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
