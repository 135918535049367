.verify-text {
    text-align: center;
    margin-top: 15px;
}

.verify-banner {
    width: 100%;
}

@media only screen and (max-width: 770px) {
    .verify-banner {
        margin-top: 10px !important;
        margin-left: 0px !important;
    }
}

.verify-send-btn {
  background: none!important;
  border: none;
  padding: 0!important;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.verify-send-btn:focus {
    outline:none;
}