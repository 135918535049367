.nav-bar-container {
  background-color: #025ab1;
  position: fixed;
  height: 100vh;
  width: 60px;
  -webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.26);
  padding-top: 29px;
  z-index: 10;
}

.nav-bar-logo {
  padding: 13px;
  margin-bottom: 58px;
}

.nav-bar-img {
  width: 100%;
  filter: brightness(0) invert(1);
}

.nav-icon {
  width: 100%;
  text-align: center;
  color: rgb(245, 245, 245) !important;
}

.nav-link {
  color: rgb(245, 245, 245) !important;
}

.nav-icon-big {
  margin: 13px 0px 13px 0px;
}

.nav-icon-large {
  margin: 18px 0px 18px 0px;
}

.nav-icon-img {
  width: 100%;
  color: white;
}

.nav-logout {
  position: fixed;
  bottom: 22px;
  cursor: pointer;
}

.fa-sign-out {
  font-size: 24px;
  z-index: 11;
  color: white;
}

.icon-btn {
  background: transparent !important;
  height: 54px;
}

@media only screen and (max-width: 770px) {

  .nav-bar-container {
    height: 60px;
    width: 100vw;
    padding-top: 0px;
  }

  .nav-bar-img {
    width: 30px;
  }

  .nav-bar-logo {
    padding: 13px;
    margin: 0px;
  }

  .nav-logout {
    left: auto;
    bottom: auto;
    right: 20px;
    top: 17px;
  }

}
