#dashboard-app {
    position: relative;
    min-height: 100vh;
}

.dashboard-content {
    position: relative;
    padding-left: 60px;
    padding-bottom: 80px;
}

.squares {
    position: absolute;
    display: block;
}

@media only screen and (max-width: 770px) {
    .dashboard-content {
        padding-left: 0px;
        padding-top: 50px;
    }
    .squares {
        display: none;
    }
}
