.ui.list > .addon-list-item {
    display: flex;
}
.subtext {
    text-align: left;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.7);
}
.addon-item {
    padding: 5px 0px 5px 0px;
    position: relative;
}

.addon-price {
    font-weight: 300;
    position: absolute;
    right: 0px;
    top: 15px;
}
.addon-icon > svg {
    display: flex;
    align-items: center;
    font-size: 1.6em;
    margin-right: 5px;
}